<template>
	<div id="app" class="mt-3">
		<KeepAlive>
			<router-view name="main" class="main"></router-view>
		</KeepAlive>
		<router-view name="footer" class="footer"></router-view>
		<Loader v-if="this.$store.state.somethingLoading"/>
		<b-modal :visible="alertShow" ok-only @ok="$store.commit('setAlertText','')">{{ alertText }}</b-modal>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue'
export default{
	components: {
		Loader
	},
	mounted: function(){
		this.$store.dispatch('getDicts')
	},
	computed: {
		alertText: function(){
			return this.$store.state.alertText
		},
		alertShow: function(){
			return this.$store.state.alertText != ""
		}
	},
}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
	.main{
		min-height: 90vh;
	}
	.hidden{
		display: none !important;
	}
</style>