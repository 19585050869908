import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '@/router'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

const store = new Vuex.Store({
	state: {
		user: 'Требуется авторизация',
		dicts: [],
		somethingLoading: true,
		item: {"guid":"", "searchName":""},
		seachPhrase: "",
		category: null,
		token: "An9D1Ra1SWCe02aw9A",
		alertText: ""
	},
	getters: {

	},
	mutations: {
		setUser: (state,payload) => {
			state.user = payload
		},
		setDicts: (state,payload) => {
			store.state.dicts = payload
		},
		setAlertText: (state,payload) => {
			store.state.alertText = payload
		}
	},
	actions: {
		authorize : (context,payload) => {
			store.state.somethingLoading = true
			axios
			.post('https://pim.9330077.ru/auth.php?login='+payload.login+'&password='+payload.password)
			.then(function (response) {
				context.commit('setUser',response.data)
				if (response.data!='Ошибка авторизации') {
					router.push('/choose')
				}
				store.state.somethingLoading = false
			})
		},
		getDicts:(context,payload) => {
			store.state.somethingLoading = true
			axios
			.get('https://pim.9330077.ru/getDicts.php')
			.then(function (response) {
				response.data.Категории.forEach(e => {
					e.state = {"expanded":true}
				})
				context.commit('setDicts',response.data)
			})
			.then(function(){
				store.state.somethingLoading = false
			})
		},
	},
	modules: {
	}
})

export default store