import Vue from 'vue'
import VueRouter from 'vue-router'
import Choose from '@/views/Choose.vue'

function lazyLoad(view){
	return() => import(`@/views/${view}.vue`)
}

Vue.use(VueRouter)

const routes = [
{
	path: '/choose',
	name: 'Choose',
	components: {
		main: Choose, //Обычная загрузка иначе глючит дерево Категорий
		footer: lazyLoad('Footer')
	}
},
{
	path: '/',
	name: 'Login',
	components: {
		main: lazyLoad('LoginForm'),
		footer: lazyLoad('Footer')
	}
},
{
	path: '/edit',
	name: 'Edit',
	components: {
		main: lazyLoad('Edit'),
		footer: lazyLoad('Footer')
	}
}
]

const router = new VueRouter({
	base: '/',
	mode: 'history',
	routes
})

export default router
