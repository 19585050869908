<template>
	<div class="container-fluid px-md-5">
		<div class="row">
			<div class="col-3">
				<div class="d-flex">
					<b-form-input @keyup.enter="seach" v-model="$store.state.seachPhrase" placeholder="Поиск по всей Номенклатуре"></b-form-input>
					<b-button :disabled="!$store.state.seachPhrase" @click="search"><b-icon-search></b-icon-search></b-button>
				</div>
				<tree
				v-if="treeData"
				ref="tree"
				:data="treeData"
				:options="treeOptions"
				@node:selected="onNodeSelected"
				v-model="$store.state.category"
				/>
			</div>

			<div class="col-9">
				<div class="row mb-2">
					<div class="col-2">
						
					</div>
				</div>
				<div class="row">
					<div class="col-10">
						<v-select @dblclick.native="choose" @mouseleave.native="fixHighlight" :loading="loading" :dropdownShouldOpen="() => {return true}" :clearable="false" :clearSearchOnSelect="false" :options="goods" label="searchName" v-model="$store.state.item" class="inline-flex">
							<div slot="no-options">Ничего не найдено, или список еще загружается</div>
							<div slot="open-indicator"></div>
						</v-select>
					</div>
					<div class="col-2">
						<router-link to="/edit"><b-button :disabled="!this.$store.state.item.guid">Открыть</b-button></router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import VueAxios from 'vue-axios'

export default {
	name: 'Choose',
	created: function(){
		if(this.$store.state.user == "Ошибка авторизации" || this.$store.state.user == "Требуется авторизация") this.$router.push('/')			
	},
	data: () => ({
			treeOptions: {
			miltiple: false,
			parentSelect: true,
			default: false,
		},
		goods: Array(),
		loading: false
	}),
	computed: {
		treeData: function(){
			return this.$store.state.dicts.Категории
		},
	},
	methods: {
		onNodeSelected(node){
			this.$store.state.seachPhrase = ""
			let guid = node.data.guid
			var self = this
			this.loading = true
			axios
			.get('https://pim.9330077.ru/getCatalog.php?q=&guid='+guid)
			.then(function (response) {
			    response.data.forEach(function(data, index) {
			      data.searchName = data.Реквизиты.Артикул + ' - ' + data.Реквизиты.Наименование
			    })
				self.goods = response.data
			})
			.then(function(){
				self.loading = false
			})
		},
		search: function(){
			if(!this.$store.state.seachPhrase) return

			this.$refs.tree.selected().unselect()
			let q = this.$store.state.seachPhrase
			var self = this
			this.loading = true
			axios
			.get('https://pim.9330077.ru/getCatalog.php?q='+q)
			.then(function (response) {
			    response.data.forEach(function(data, index) {
			      data.searchName = data.Реквизиты.Артикул + ' - ' + data.Реквизиты.Наименование
			    })
				self.goods = response.data
			})
			.then(function(){
				self.loading = false
			})
		},
		choose: function(){
			this.$router.push('/edit')
		},
		fixHighlight: function(){

			let highlights = document.querySelectorAll('.vs__dropdown-option--highlight')

			if(highlights){
				highlights.forEach(highlight => {
					highlight.classList.remove("vs__dropdown-option--highlight")
				})
			}			
		}
	}
}
</script>

<style>
.vs__selected{
	opacity: 1 !important;
	font-size: large !important;
}
.vs__dropdown-option--selected{
    background: var(--vs-dropdown-option--active-bg);
    color: var(--vs-dropdown-option--active-color);
}
</style>