import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import 'vue-select/dist/vue-select.css'
const vSelect = () => import('vue-select')
Vue.component('v-select', vSelect)

const LiquorTree = () => import('liquor-tree')
Vue.component('tree', LiquorTree)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')